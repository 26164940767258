//  [ 서브 상세 페이지 데이터 ]
// 1. brand_detail : 브랜드 상세 페이지
// 2. story_detail : 스토리 상세 페이지
// 3. collection_detail : 컬렉션 상세 페이지

/*************************
 img 4 / tit 4 / text 5
 {
    idx: 1,
    img:[1,2,3,4]
    tit:[1,2,3,4]  --> collection_detail만 5개! 
    text: [1,2,3,4,5]
    video: "",
 }

  1) idx : 유일키
  2) img : 이미지파일명
  3) tit : 서브글 타이틀 제목
  4) text : 내용 단락
  5) video : 비디오파일명
 
 ************************ */

const brand_detail = [
  {
    idx: 1,
    img: [
      "brand_detail1-1.jpg",
      "brand_detail1-2.jpg",
      "brand_detail1-3.jpg",
      "brand_detail1-4.jpg",
    ],
    tit: [
      "About PENHALIGON'S",
      "William Penhaligon",
      "Fragrant Trilogy",
      "Scents of British Tales",
    ],
    text: [
      `1870년 영국에서 설립된 펜할리곤스 퍼퓸 하우스는 오랜 전통을 현재까지 이어가고 있습니다.
         
         *영국 왕실이 그 가치와 감각을 인정한 제품이나 생산자에게 신뢰의 상징으로 로열 워런트를 수여하는데, 펜할리곤스는 총 3개의 로열 워런트를 보유하고 있습니다. **펜할리곤스는 영국 왕실 향수로서의 전통을 지키면서도 누구나 탐낼만한 히스토리와 매력을 갖춘 향수입니다. 차별화된 후각적 감성과 모던하면서도 정교한 디자인은 펜할리곤스만의 아이코닉한 가치를 나타냅니다.*** 다양하고 컬러풀한 리본 데코레이션, 세련된 컬러 매칭 센스, 독특한 소재의 펜할리곤스 바틀 디자인은 영국적 감성과 왕실의 품격이 어우러져 펜할리곤스만의 아이덴티티로 자리매김하고 있습니다. **영국 왕실이 인정한 150년 노하우의 품격과 영국적 감성을 만나보세요.`,

      `"향수는 후각으로 그 감성을 전달한다."**영국 왕실이 사랑하는 펜할리곤스는 1870년에 설립자인 윌리엄 펜할리곤에 의해 시작했습니다.* 1870년에 런던의 저민 스트리트에 첫 퍼퓸 하우스를 설립하고 1872년에는 첫 향수인 ‘하맘 부케’를 출시했습니다. *
         윌리엄 왕자가 반한 ‘블렌하임 부케’는 1902년에 영국 옥스퍼그셔에 위치한 말버러 공작의 블렌하임 대저택에서 영감을 받아 탄생한 향수입니다.**
         
         이와 같이 펜할리곤스는 늘 주변의 다양한 장소와 순간에서 아이디어를 얻었습니다. 예를 들어 옷을 맞추러 양장점에 가도, 그곳 특유의 패브릭 향에 끌려 향수를 제작하기도 했습니다.**
         그런 점에서 펜할리곤스는 우리가 살고 있는 ‘현재’를 반영하는 브랜드입니다. 지금도 창의적 유산과 영국적 위트, 그리고 브랜드 철학은 그대로 이어지고 있습니다.`,

      `펜할리곤스의 컬렉션은 크게 '브리티시 테일즈', '트레이드 루트', '포트레이트' 세 가지로 나눕니다.**
         브리티시 테일즈에는 영국의 전통과 정서를 가장 잘 드러내는 향수들이 포함됩니다. 오랜 기간 사랑받아온 블렌하임 부케, 달빛을 오마주한 루나, 브랜드의 시작이었던 하맘 부케 등이 대표작입니다. **블렌하임 부케는 1902년 9대 말버러 공작의 의뢰로 제작된 향수로, 시트러스 오일과 우디, 스파이시한 향기를 조합해 지금도 세련된 느낌을 전합니다.
         `,
      `트레이드 루트 컬렉션은 19세기 런던 부두의 활기차고 모험적인 분위기에서 영감을 얻었습니다. 이국적 물품과 향신료가 실린 배에서 느낄 법한 신선하고 독특한 향기를 구현했습니다. **그중 엠프레사가 가장 주목할 만한데, 진주와 실크 등의 아름다움을 블러드 오렌지, 베르가모트, 만다린 향으로 표현하고 복숭아, 장미, 바닐라, 샌델우드 등으로 이어가며 따뜻하고 여성스러운 향을 완성했습니다.`,

      `마지막 포트레이트 컬렉션은 19세기 말 영국 상류사회의 스캔들과 이야기를 독특한 보틀 디자인으로 표현했습니다. 영화 한 장면 같은 박스 디자인과 오브제로서의 가치를 지닌 보틀은 고급스럽고 웅장한 포트레이트의 정수를 보여줍니다. **특히 '글레노키의 제왕'에 등장하는 수사슴 조각을 본딴 로드 조지 향수는 라벤더, 럼, 통카빈의 아로마티크 향과 시더우드, 베티버 향이 어우러져 묵직하고 오묘한 향으로 큰 인기를 끌었습니다.`,
    ],
    video:"brand_detail1.mp4",
  },
];
const story_detail = [
  {
    idx: 1,
    img: [
      "story_detail1-1.jpg",
      "story_detail1-2.jpg",
      "story_detail1-3.jpg",
      "story_detail1-4.jpg",
    ],
    tit: [
      "Sailing the world with JONATHAN WRIGHT",
      "The Globemaker",
      "The Globe",
      "The Process",
    ],
    text: [
      `Penhaligon's는 지구 제작자인 뛰어난 조나단 라이트(Jonathan Wright)에게 Penhaligon 향수의 향기로운 세계를 기념하기 위해 맞춤형 지구본을 만들어 달라고 요청했습니다.`,

      `아마도 영국에서 가장 존경받는 향수 회사인 Penhaligon's는 영국의 밝고 젊은 사람들, 특히 특이하거나 특이한 기술을 가진 사람들의 예술성을 옹호하지 않을 수 없습니다.* 실제로 영국 장인 정신의 세계로의 최근 진출을 통해 우리는 항해 항해와 식물 일러스트레이션에 발을 담그는 것을 보았습니다.** 우리는 뛰어난 글로브 제작자인 Jonathan Wright에게 Penhaligon 향수 제조의 경이로운 세계에서 영감을 받아 맞춤형 글로브를 제작하도록 의뢰했습니다.`,

      `조나단 라이트(Jonathan Wright)는 맞춤형 지구본 보존 및 제작을 전문으로 하는 지구본 제작자의 대가입니다.* Bellerby & Co. Globemakers의 지도 하에 8년 동안 Wright는 역사적인 지구본 복원 기술을 습득했을 뿐만 아니라 지도 제작 기술을 개발하여 새로운 지구본을 만들었습니다.** 이제 Wright는 자신의 사업을 시작했으며 계속해서 자신의 기술을 연마하는 동시에 새로운 세대의 제작자와 지도 제작자를 교육하고 있습니다. *그는 또한 영국 장인 정신의 우수성을 지원하는 데 전념하는 자선 단체인 Queen Elizabeth Scholar Trust(QEST)에서 공부했으며, 동문 이사이기도 합니다.`,

      `Penhaligon's는 Wright에게 접근하여 Penhaligon의 많은 퇴폐적인 향기를 담은 먼 곳의 식물 성분의 향기로운 제품에 영감을 준 지구본을 만들었습니다.* 지구본은 선원들이 수세기 동안 바다를 항해하기 위해 사용했던 별의 지도인 천구를 모방하고 하늘의 별자리를 사용하여 위치를 결정합니다.** 식물 요소를 묘사한 초기 일러스트레이션에서는 Halfeti 및 Penhaligon의 AlUla와 같은 향수가 발견되었지만 Wright는 이를 확장하여 Trade Routes 컬렉션에 사용되는 성분과 더 넓은 향수 세계를 포함시켰습니다.** 또한 우리 윌리엄 펜할리곤(William Penhaligon)의 별자리를 포함하여 펜할리곤(Penhaligon)의 유산에 대한 멋진 고개를 끄덕이는 작품도 있습니다. 열기구도 찾을 수 있을까요?`,

      `Wright는 지구에서 볼 수 있는 66개의 주요 별자리를 지도로 그린 다음, 그 모양을 따라 손으로 그린 ​​식물 그림을 그려 맞춤형 점성술 지도를 만드는 것으로 지구본을 시작했습니다. *이것들은 지도 제작 프로그램으로 스캔되었고 지구본 모양을 모방하도록 디지털 방식으로 변경되었습니다.* 일단 인쇄되면 섹션을 조심스럽게 잘라 두 개의 돔형 몰드로 만든 빈 구에 붙입니다. 그런 다음 Wright는 삽화에 수채화 물감을 여러 겹 추가하여 빈티지 효과를 낸 다음 무광택 마감 처리로 지구본을 칠하고 고객 지구본 스탠드에 전시했습니다. **완제품을 우리가 직접 말한다면 이 세상에서 완전히 벗어난 완벽히 새로운 것 이었습니다.`,
    ],
    video: "story_detail1.mp4",
  },
  {
    idx: 2,
    img: [
      "story_detail2-1.jpg",
      "story_detail2-2.jpg",
      "story_detail2-3.jpg",
      "story_detail2-4.jpg",
    ],
    tit: [
      "A FLORAL FEAST- Talking with Hamish",
      "Introduce us to Hamish! Tell us a little about yourself.",
      "How did you begin as a botanical designer?",
      "Talk us through your inspiration for Penhaligon's window display.",
    ],
    text: [
      `우리는 뛰어난 꽃 조각가인 Hamish Powell과 함께 꽃에 대한 그의 매력과 Penhaligon의 Chelsea in Bloom 디스플레이에 대한 그의 최신 작품에 대해 논의했습니다.`,

      `여름이 꽃을 피우기 시작하면 펜할리곤스는 향기로운 꽃향기로 코를 돌립니다. *물론 우리는 Chelsea in Bloom에 대해 이야기하고 있습니다. 연례 꽃 축제는 디스플레이가 눈과 코를 즐겁게 하는 색상과 창의성을 축하하는 행사입니다. **올해 Penhaligon's는 꽃 조각가 Hamish Powell에게 King's Road 매장을 위한 완벽한 Penhaligon's 디스플레이를 만들어 고객과 심사위원 모두를 현혹시킬 것을 요청했습니다. **우리는 Hamish와 함께 앉아 디스플레이 뒤에 숨은 영감에 대해 논의했습니다.`,

      `사람은 자신이 먹는 대로 변한다는 말이 있죠. 실제로 저는 제가 꾸미는 대로 변합니다.**
        저는 꽃병에 꽂은 줄기만큼이나 섬세하고 기발하며, 햇볕을 갈망하고 겨울을 두려워하며, 약간의 과시욕이 있고, 숲속 바닥의 얼룩덜룩한 빛 아래서 완전히 집처럼 편안하게 지내는 꽃입니다. **저는 스스로를 플로리스트라고 부르지만, 그보다 더 깊고 압도적이며 모든 것을 아우르는 느낌입니다.**
        꽃은 저의 뮤즈이자 매개체입니다. 꽃꽂이를 하지 않는다면 꽃에 대해 글을 쓰고 있죠. 시와 플로리스트는 일종의 감정 표현, 자연에 대한 러브레터, 이 삶에 대한 감사 등 같은 목적지로 향하는 두 가지 다른 길이라고 생각합니다.`,

      `많은 사람들이 그러하듯 저도 절망적인 취미로 시작했습니다. 저는 맨체스터 대학교에서 식물 생명공학 학사 학위를 취득하기 위해 공부하던 가난한 학생이었습니다.** 제 직업과 조금이라도 연관성이 있는 아르바이트를 찾아 도시의 모든 꽃집에 가짜 꽃꽂이 경력으로 가득 찬 이력서를 직접 전달했습니다.* 한 곳에서만 연락이 왔는데, 시간이 흘러 꽃이 날아갔고 3년 후 저는 그 가게의 경영을 맡게 되었습니다! 같은 해 여름, 저는 졸업을 하고 과학과 예술의 갈림길에 서게 되었습니다. 꽃을 통제할 것인가, 아니면 꽃이 저를 통제하게 할 것인가.**
        플로리스트리 덕분에 꽃을 통해 저를 표현하는 방법을 배웠어요. 어떻게 실험실 가운을 입고 그걸 외면할 수 있을까요? 그래서 8년이 지나고 도시를 옮긴 후에도 제 스튜디오를 통해 매일매일 도시와 자연을 더 많이 연결할 수 있게 되었죠.`,

      `올해 첼시 인 블룸의 테마는 '꽃의 향연'입니다. *이 주제는 마비될 정도로 광범위한 창작 범위를 가진 멋진 내러티브이기 때문에 저는 영감을 얻기 위해 펜할리곤의 헤리티지에서 찾아보았습니다.* 1837년 콘월에서 태어난 브랜드 창립자 윌리엄 펜할리곤의 생가를 찾았죠.**

        바로 그거였어요! 콘월에서 가장 잘 알려진 음식 중 하나인 콘월 크림 티의 스타, 겸손하게 상징적인 스콘을 만나보세요.**
        
        영국인과 방문객 모두가 즉시 알아볼 수 있고 소중히 여기는 이 음식은 즉각적인 유레카의 순간이었습니다. 저는 펜할리곤이 런던 향수의 혼돈 속에서 스콘을 먹으며 고향 콘월을 부드럽게 회상하는 모습을 상상하는 것을 좋아합니다.**
        
        킹스로드 매장에 가서 직접 눈으로 확인해보세요. 첼시 인 블룸은 5월 20일부터 26일까지 개화합니다. 그리고 그곳에 있는 동안 언제든 들러서 마음에 드는 향기를 골라보세요!`,
    ],
    video: "story_detail2.mp4",
  },
  {
    idx: 3,
    img: [
      "story_detail3-1.jpg",
      "story_detail3-2.jpg",
      "story_detail3-3.jpg",
      "story_detail3-4.jpg",
    ],
    tit: [
      "AN ODE TO ALULA",
      "Creativity Carved In Stone",
      "Discover The Fragrance",
      "and..",
    ],
    text: [
      `전통 예술 학교와의 파트너십`,

      `수십 년 동안 펜할리곤스는 지역 장인 정신과 문화를 헌신적으로 옹호해 왔습니다.* 기발하고 멋진 간판 작가, 코르셋 제작자, 예술가들을 기념해온 펜할리곤스의 알울라는 전 세계의 스토리텔러들을 기념하기 위해 더 멀리 눈을 돌려야 했습니다. **

    현지 장인들이 자신의 유산에 대한 진정한 자부심을 느낄 수 있도록 영감을 주는 왕립 재단에서 운영하는 창의적인 허브인 알울라의 전통 예술 학교를 발견한 것은 놀라운 일이 아닙니다(특히 저희의 마음속 깊이 자리 잡고 있는 사명). 이곳에서는 장인들이 주변의 드라마틱하고 아름다운 풍경을 반영한 제품을 디자인하고 제작하도록 장려합니다.`,

      ` 펜할리곤스는 최신 향수를 만들면서 알울라의 자연의 아름다움에서 영감을 받지 않을 수 없었고, 전통예술학교에 새로운 향을 기념하는 무언가를 만들어 달라고 요청했습니다.** 우리의 주요 성분에 경의를 표하는 맞춤형 향 홀더를 제작하는 것은 당연한 일이라고 생각했습니다. `,

      `알울라의 현지 장인들이 디자인하고 제작한 맞춤형 향로는 현지 커뮤니티의 창의성을 바탕으로 다양한 문화와 전통의 다양성과 조화를 보여주며, 주변의 모래사막 풍경에 묻혀 있습니다.`,

      `대추야자가 수확의 바람에 흔들리며 시트러스와 무화과 향을 선사하는 이야기. *자두와 향신료. 향과 사프란. 향신료 시장과 영성이 깃든 역사. **창의성과 공동체에 대한 이야기가 문화적 태피스트리로 이어집니다. `,
    ],
    video: "story_detail3.mp4",
  },
  {
    idx: 4,
    img: [
      "story_detail4-1.jpg",
      "story_detail4-2.jpg",
      "story_detail4-3.jpg",
      "story_detail4-4.jpg",
    ],
    tit: [
      "A DAY OUT WITH MUMMY DEAREST",
      "It's Always Time For Tea",
      "A Front Row Seat",
      "A Candlelit Dinner",
    ],
    text: [
      `어버이날을 맞아 어머니를 모시고 외출할 계획이신가요?* 펜할리곤스가  고객들을 위해 몇 가지 제안을 해드릴게요.`,

      `어머니가 신발끈을 묶어주고 도시락을 준비하던 때가 엊그제 같은데, 왜 그럴까요? 시간이 참 빠르네요.그리고 어느새 3월 10일 일요일이 다가왔습니다. 부모님의 저녁 식사, 병원 방문, 음악 연주회 등 그간의 노고를 어버이날 깜짝 선물로 보답할 때입니다. **펜할리곤스가  몇 가지 아이디어를 알려드릴게요.
         `,
      `애프터눈 티는 매우 적절하면서도 결코 잘못될 수 없는 메뉴입니다. 신선한 케이크, 구운 스콘, 따뜻한 찻주전자 - 이웃에 대한 수다를 떨거나 어린 시절을 회상하며 몇 시간 동안 시간을 보내기에 이보다 더 좋은 방법이 있을까요?** 물론 적절한 장소를 선택해야 하는데, 극장 로열 드루리 레인(Theatre Royal Drury Lane)의 그랜드 살롱(Grand Saloon)보다 '어머니, 감사합니다'라는 말을 더 잘 표현할 수 있는 곳은 없습니다. *지난 크리스마스에 펜할리곤의 축제 케이크를 만들었던 제빵사 릴리 바닐리의 케이크가 포함된 어머니날 전용 메뉴와 함께 고급 본차이나 컵에 차를 마시며 극장과 같은 리젠시 분위기를 만끽하세요. **그리고 찻잔이 비워지면 펜할리곤의 웰링턴 스트리트에서 한 걸음, 조금만 걸어가면 어머니도 향수를 사러 오고 싶어 하실 거예요.`,

      `Royal Opera House에서 우아함과 세련미가 가득한 공연을 어머니께 선물해 보세요. 운이 좋다면 Manon 의 마지막 공연(3월 8일까지 공연)을 보거나 백조의 호수(3월 6일 - 6월 28일)를 가장 먼저 볼 수 있는 행운을 잡을 수도 있습니다. *두 분 모두 공연에 걸맞은 옷을 입으시겠지만 눈부신 향기를 풍기는 것도 잊지 마세요. 극장에 가는 것은 여인보다 더 멋진 옷차림은 없으니 'The Revenge of Lady Blanche  향수'를 추천해 드립니다.`,

      `사람의 마음은 배를 통해 간다는 말이 있지만, 어머니에 대해서도 마찬가지라고 누가 말할 수 있을까요?* 이번 어버이날에는 어머니를 위해 주방에서 보낸 모든 시간에 대한 보답으로 근사한 저녁 식사로 감사의 마음을 전하세요. 펜할리곤스가 바로 그 해답을 제시합니다.** 더 아이비에서 선보이는 어버이날 메뉴 - 최고의 향수를 뿌리고 차려입는 것보다 더 좋은 상황이 있을까요? *또한, 맛있는 저녁 식사가 끝나면 어머니에게 펜할리곤스 캔들을 선물하여 어머니가 내 삶의 빛이라는 것을 보여줄 수 있는 작은 선물도 준비되어 있습니다.`,
    ],
    video: "story_detail4.mp4",
  },
];
const collection_detail = [
  {
    idx: 1,
    img: [
      "collection_detail1-1.jpg",
      "collection_detail1-4.jpg",
      "collection_detail1-3.jpg",
      "collection_detail1-2.jpg",
    ],
    tit: ["The British Tales Collection", "Some of our favorites","The British Tales","And..","British Tales",],
    text: [
      `더 브리티시 테일즈 컬렉션은 가장 영국적인 감성과 고전 그리고 이야기를 담아낸 향수 라인입니다.* 이 컬렉션은 영국의 문학, 전설, 역사적 인물들을 테마로 하여 각 향수가 독특한 이야기를 담고 있습니다. *펜할리곤스는 이러한 이야기를 향수로 재현하여 사용자가 향기를 통해 영국의 문화와 유산을 체험할 수 있도록 합니다.`,

      `몇가지의 대표 컬렉션 향수들을 소개합니다. **<strong>블렌하임 부케 (Blenheim Bouquet)</strong>**영국 옥스퍼드셔에 위치한 말버러 공작의 블렌하임 대저택에서 영감을 받아 탄생한 향수입니다. 1902년에 출시된 이 향수는 상쾌한 시트러스 향과 우디 노트가 조화를 이루어 클래식하면서도 세련된 느낌을 줍니다.* 이 향수는 블렌하임 대저택의 웅장함과 고귀함을 반영하여, 영국의 전통과 품격을 상징합니다.
         
         **<strong>쿼커스 (Quercus)</strong>** 영국의 자연과 참나무 숲에서 영감을 받아 제작되어 영국의 풍부한 자연유산과 전통적인 아름다움을 기리는 향수입니다.`,

      `**<strong>사토리얼 (Sartorial)</strong>** 런던의 저명한 양복점 사빌 로우(Savile Row)에서 영감을 받아 탄생한 향수로 영국의 양복 제작 전통과 장인 정신을 기리는 향수입니다.
         
         **<strong>쥬니퍼 슬링 (Juniper Sling)</strong>** 1920년대 런던의 활기찬 사회와 진(Gin) 문화를 반영한 향수입니다.* 이 향수는 아로마틱 스파이시 계열로, 진의 주요 성분인 주니퍼 베리의 향을 중심으로 다양한 향신료가 어우러져 독특하고 활기찬 향을 선사합니다.* 쥬니퍼 슬링은 런던의 사회적 활력과 독특한 문화적 분위기를 담아냅니다.`,

      `이처럼 더 브리티시 테일즈 컬렉션은 펜할리곤스의 전통을 기리며, 향수를 통해 영국의 과거와 현재를 연결합니다. 각 향수는 특별한 이야기와 감성을 담고 있으며, 그 이야기를 향을 통해 전달합니다.** 이 컬렉션은 전통적인 원료와 현대적인 향조가 결합되어 고전적이면서도 현대적인 감각을 제공합니다.  *엘리자베스 로즈는 고전적인 장미 향에 현대적인 그린 노트를 더해 세련됨을 강조하고, 더 페이버릿은 개화한 꽃들의 향기와 따뜻한 앰버, 샌달우드 노트를 통해 우아함을 표현합니다.`,

      `결국, 더 브리티시 테일즈 컬렉션은 펜할리곤스의 전통과 영국의 유산을 기리는 라인으로, 향수를 통해 영국의 풍부한 문화적 이야기를 전달합니다.** 각 향수는 특별한 테마와 감성을 담고 있으며, 여러분에게 더욱 풍부한 향기 경험을 제공합니다.`,
    ],
    video: "collection_detail1.mp4",
  },
  {
    idx: 2,
    img: [
      "collection_detail2-4.jpg",
      "collection_detail2-3.jpg",
      "collection_detail2-1.jpg",
      "collection_detail2-2.jpg",
    ],
    tit: [
      "Portraits Collection",
      "Worldview of Portrait",
      "Portrait family tree ",
      "And..",
      "Portraits",
    ],
    text: [
      `영국 귀족사회를 배경으로 한 소설 속 가상 인물들을 개성 넘치는 향으로 표현한 컬렉션입니다. 

         신사의 나라 영국에서 일어나는 미스터리하면서도 유머러스한 로드 조지와 그 주변 인물들. 이 영국 귀족들은 과연 우아하기만 할까요?`,

      `**<strong>Portraits Collection Chapter 1
**</strong> 신사의나라 영국에서 일어나는 미스터리하면서도 유머러스한 로드 조지와 그 주변 인물들. 
늘 강인해 보이는 로드 조지와 그의 아내 레이디 블랑쉬는 드라마틱 한 이야기를 가지고 있습니다.* 블랑쉬 여사는 조지 경을 암살하려는 은밀한 계획을 꾸미고 있는 걸 볼 수 있습니다.
그들의 딸 더치스 로즈는 최근 듀크 넬슨과 결혼했지만 결혼 생활이 행복하지만은 않습니다.**
아마 이 둘이 공통적으로 장미를 사랑한다는 걸 알았다면 결혼 생활이 행복하지 않았을까요? 아이러니하지만 그렇게 놀랍지도 않습니다.

**<strong>Portraits Collection Chapter 2**</strong>
로드 조지를 둘러싼 비밀이 여기 펜할리곤스 챕터 2에서 클라라와 래드클리프의 등장과 함께 밝혀집니다.*
클라라는 로드 조지의 내연녀로 평범하지 않은 매력을 소유한 여성입니다.
로드 조지와 클라라 사이에서 태어난 사생아, 래드클리프는 영국에서 자극적인 파티들을 돌아다니기로 유명합니다. **그는 파티 광으로 럼과 담배 향의 흔적을 남깁니다

**<strong>Portraits Collection Chapter 3**</strong>
이 흥미로운 영국 귀족 이야기는 소프 오페라(Soap Opera)가 흥행했던 시대를 생각나게 합니다. 챕터 3에서는 이집트에서 넘어온 남매와 로드 조지 사이의 이야기를 들려줍니다.*
로드 조지와 함께 일하는 소한은 성공의 야망을 품고 영국으로 넘어와 열심히 일을 하며 부를 쌓습니다.
그의 여동생 야스민 또한, 영국에서 결혼할 남자를 만나기 위해 이집트에서 왔습니다. **그녀의 매력은 많은 영국 신사들을 홀립니다.

**<strong>Portraits Collection Chapter 4**</strong>
포트레이트 컬렉션의 새로운 챕터 4. 최고 권위자, 강인한 여자 가장, 무자비한 도로시아 백작 부인이 등장합니다. *그녀는 자칫 거만해 보일 수도 있지만, 젊은 남자들에 대한 그녀의 열정은 아주 유명하게 알려져 있습니다.
그녀의 옆에는 프랑스 남자, 무슈 보르가르가 늘 함께합니다. **그는 성적 매력으로 도로시아 백작부인을 정복했습니다. 사람들은 그가 뭔가 숨기고 있다고 생각합니다.
`,

      `포트레이트 세계관 속의 인물들과 사건들을 한장의 사진을 통해 확인해보세요.`,

      `
**<strong>Portraits Collection Chapter 5**</strong>
이색적인 캐릭터의 등장! 블랑쉬 여사의 여동생, 콘스탄스는 신여성의 아이콘이라고 할 수 있습니다.* 드레스가 아닌 바지를 입고, 남자들을 그녀의 발밑 아래에 두죠.
새로운 챕터 5의 또 다른 등장인물인 미스터 샘은, 미국에서 왔습니다. 영국에 온 미국인, 빠른 속도를 즐기고 호탕하게 웃으며 말하곤 합니다. **그의 웃음은 유부녀 더치스 로즈의 마음을 사로잡기 충분했어요.

**<strong>Portraits Collection Chapter 6**</strong>
새로운 상큼함을 가지고 온 포트레이트 컬렉션의 새로운 인물 커즌 플로라와 커즌 매튜.*
사랑스럽고 순수한 플로라는 더치스 로즈의 사촌으로, 남매인 매튜의 장난들을 절대 놓치지 않습니다. **언제나 바르고 옳은 플로라 또한 복수가 큰 즐거움이라고 하는데…


**<strong>Portraits Collection Chapter 7**</strong>
정글의 미스터리함을 가지고 온 포트레이트 컬렉션의 새로운 인물 테러블 테디와 헐트리스 헬렌.*
"Friends with benefits." 서로를 이용하는 관계일까요? 아직 그 둘의 사이는 묘하게 흘러갑니다. 썸이라고 하기엔 너무나 친밀하고, 연인이라고 하기엔 시크한 그 둘. 서로 가볍게 즐기는 사이일까요?**
자유롭게 여자들을 만나던 테디는 정글에 사냥을 나가서 매력적인 헬렌을 만나게 됩니다. 테디의 마음을 완전히 사로잡은 헬렌의 반응은 무관심합니다.** 테디는 그의 짝을 결국엔 만날 수 있을까요? 아직 그는 남자라기 보다 소년이라 할 수 있겠네요.`,

      `이렇게 포트레이트는 각 향수의 독특한 이야기와 감성을 담고 있으며, 그 이야기를 향을 통해 전달합니다. ** 이 컬렉션은 영국 귀족사회의 다양한 인물들과 사건들을 향수로 재현하여 사용자가 향기를 통해 영국의 문화와 유산을 체험할 수 있도록 합니다.* 포트레이트 속 펜할리곤으로 등장하는 펜할리곤스의 창립자 윌리엄은 이 복잡한 관계들 속에서 관찰자의 시점으로 향기로 그들의 내면을 포착하고 표현해 냅니다.`,
    ],
    video: "collection_detail2.mp4",
  },
  {
    idx: 3,
    img: [
      "collection_detail3-1.jpg",
      "collection_detail3-4.jpg",
      "collection_detail3-2.jpg",
      "collection_detail3-3.jpg",
    ],
    tit: ["Trade Routes Collection", "Journey to a Bygone Era​","Various ingredients and spices","And..","Trade Routes",],
    text: [
      `무역업이 활발했던 19세기 런던 항구를 가득 채운 비단, 향신료, 진귀한 목재 등 고급 무역품에서 영감을 받은 컬렉션입니다.`,

      `펜할리곤스는 스토리텔링에 대한 애정으로 역사책의 한 페이지에서 영감을 받아 무역로 컬렉션을 탄생시켰습니다.** 19세기 초 런던 부두에서 거래되던 명품에서 영감을 받은 이 향기로운 보물들은 시간을 거슬러 올라가는 후각 여행으로 여러분을 안내합니다.
      
      19세기 후반 영국의 해상 무역과 탐험을 테마로 한 이 향수 라인들은 19세기 후반의 영국의 전 세계를 무대로 무역을 펼치며 다양한 이국적인 재료와 향신료를 들여온 것에서 영감을 받았습니다. `,

      `이 컬렉션은 그러한 무역로에서 영감을 받아 독특하고 풍부한 향을 만들어냅니다.* 각 향수는 무역 항로를 통해 접할 수 있었던 특정 지역이나 재료를 중심으로 하여 향기를 구성합니다.`,

      `각 향수는 독특한 이야기를 담고 있으며, 그 이야기를 향을 통해 전달합니다.`,

      `트레이드 루트 컬렉션을 통해 펜할리곤스의 역사적 유산과 탐험 정신을 기리고 19세기 영국의 무역 모험을 경험해 보세요!`,
    ],
    video: "collection_detail3.mp4",
  },
  {
    idx: 4,
    img: [
      "collection_detail4-1.jpg",
      "collection_detail4-2.jpg",
      "collection_detail4-3.jpg",
      "collection_detail4-4.jpg",
    ],
    tit: ["Potions & Remedies", " Penhaligon's Secret Laboratory","Some of our favorites","And..","Potions & Remedies",],
    text: [
      `포션앤레미디 컬렉션은 윌리엄 펜할리곤의 비밀 실험실에서 영감을 받아 탄생한 특별한 향수 라인입니다.`,

      `각각의 향수는 고유한 이야기와 치유 효능을 담고 있어 사용자에게 특별한 경험을 선사합니다. 
      **<strong>리퀴드 러브</strong>* 사랑을 상징하는 플로럴 계열의 향수로,* 달콤한 꽃 향기가 마음을 따뜻하게 만들어줍니다. 
      **<strong>밤 오브 캄</strong>* 평온함을 상징하는 플로럴 오리엔탈 계열의 향수로,* 조화로운 향기가 스트레스 받는 일상에서 내면의 평화를 찾고 싶을 때 특별한 위로를 제공합니다.`,

      `**<strong>오 디 오데시티</strong>* 대담함을 상징하는 스트롱 오리엔탈 계열의 향수로,* 특별한 순간에 자신감을 끌어올려주는 향기는 화려한 분위기를 만들어줍니다.
      
      **<strong>키스 오브 블리스</strong>* 상쾌함을 상징하는 플로럴 프레시 계열의 향수로, 청량한 향기는 마음을 환기시켜주며, 상쾌한 기분을 선사합니다.`,

      `**<strong>브라 브라 브룸</strong>* 활기찬 분위기를 상징하는 플로럴 프루티 계열의 향수로,* 생기 넘치는 향기는 활동적인 모습을 보여주며, 에너지를 끌어올려줍니다.`,

      `포션앤레미디 컬렉션은 자연의 힘을 담아낸 특별한 향수 라인으로,* 독특하고 황홀한 경험으로 여러분에게 특별한 순간을 선사할 것 입니다!`,
    ],
    video: "collection_detail4.mp4",
  },
];

export { brand_detail, story_detail, collection_detail };
